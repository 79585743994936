import * as React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout/index.js";
import RenderHome from "../containers/Home/index.js";

const HomePage = ({ data }) => {
  return (
    <Layout>
      <RenderHome />
    </Layout>
  );
};

export default HomePage;
