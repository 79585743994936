import React, { useEffect } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Rellax from "rellax";
import { Link } from "gatsby";

import { BlogSection } from "../../components/Blog";
import { CallToAction } from "../../components/CallToAction";
import { Seo } from "../../utils/Seo";

import iPadBack from "../../images/back-ipad.png";
import iPadFront from "../../images/front-ipad.png";

import iPhoneBack from "../../images/dashboard_phone_back.png";
import iPhoneFront from "../../images/dashboard_phone.png";

const RenderHome = () => {
  useEffect(() => {
    var rellax = new Rellax(".rellax");
  }, []);

  return (
    <>
      <Seo
        title="eCommerce and Digital Development | Montebello.digital"
        desc="We help businesses make a bigger impact with software & design."
      />
      <section className="agency-hero">
        <Container fluid className="agency-hero-container">
          <div className="hero-content">
            <h1 className="pre-title mb-3">Rise Beyond Limits.</h1>
            <h1 className="title">
              We help businesses grow with software &amp; design.
            </h1>
          </div>
        </Container>
      </section>

      <section className="what-we-offer">
        <Container>
          <Row className="align-items-start _align-items-center">
            <Col md={{ span: 4 }} className="float-imgs text-center">
              <img
                data-rellax-speed="0.5"
                className="rellax hero-img--back"
                src={iPadBack}
                alt=""
              />
              <img
                data-rellax-speed="-0.35"
                className="rellax hero-img--screen"
                src={iPadFront}
                alt=""
              />
            </Col>
            <Col md={{ span: 8, offset: 0 }}>
              <h3 className="pre-title">
                What we do<span className="color-brand">.</span>
              </h3>
              <h3 className="title mt-0">Your trusted tech partner.</h3>
              <p className="para-mega mb-4">
                With a decade in the business and a diverse portfolio of
                projects, we're not just tech experts; we're your partners in
                navigating the digital landscape to bring you success.{" "}
              </p>

              <hr className="my-5 _d-md-none" />
              <div className="mb-5">
                <h4 className="title-medium">Software Development</h4>
                <p>
                  We bring your ideas to life with tailor-made software that
                  solves your unique challenges. Get a solution that fits like a
                  glove, enhancing your operations and boosting efficiency.
                </p>
                <Link to="/services" className="btn-link d-block">
                  Learn More
                </Link>
              </div>
              <div className="mb-5">
                <h4 className="title-medium">Web Design & Development</h4>
                <p>
                  Your website is your online storefront, and we make it
                  inviting. Expect a design that not only looks great but also
                  drives engagement and conversions.
                </p>
                <Link to="/services" className="btn-link d-block">
                  Learn More
                </Link>
              </div>
              <div>
                <h4 className="title-medium">Technology Consulting</h4>
                <p>
                  Navigating the tech landscape can be tricky. Let us be your
                  guide. We help you make the smartest tech choices for
                  long-term success, so you can focus on your business.
                </p>
                <Link to="/services" className="btn-link d-block">
                  Learn More
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <CallToAction
        pretitle="Ready to scale your business up a level?"
        title="Let us know what you need."
        btnText="Contact Us"
        btnLink="/contact"
      />

      <section className="our-services">
        <Container>
          <Row className="align-items-center">
            <Col md={{ span: 4 }} className="float-imgs text-center">
              <img
                data-rellax-speed="0.05"
                className="rellax hero-img--back"
                src={iPhoneBack}
                alt=""
              />
              <img
                data-rellax-speed="0"
                className="rellax hero-img--screen"
                src={iPhoneFront}
                alt=""
              />
            </Col>
            <Col md={{ span: 8, offset: 0 }}>
              <h3 className="pre-title">
                Our Process<span className="color-brand">.</span>
              </h3>
              <h3 className="title mt-0">
                From digital dreams to real-world wins.
              </h3>
              <p className="para-mega">
                We're not in the business of selling services, but solving
                problems. We love helping our clients reach their goals, and we
                know the steps to get there. Rely on our trusted process to
                bring your ideas to life.
              </p>
              <div>
                <a className="btn btn-brand" href="/process">
                  See Our Process
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="home-blog">
        <div className="home-blog-before"></div>
        <BlogSection limit={6} />
        <div className="home-blog-after"></div>
      </section>
    </>
  );
};

const RenderHomePage = () => {
  return RenderHome();
};

export default RenderHomePage;
